import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
	selector: 'app-status-bar',
	templateUrl: './status-bar.component.html',
	styleUrls: ['./status-bar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class StatusBarComponent implements OnInit {
	@Input() statusBarInfo: any;

	constructor(
		private bsModalService: BsModalService,
		private router: Router
	) { }

	ngOnInit() { 
	}

	goShipment(event, status = 'NW') {
		event.preventDefault();
		localStorage.setItem('valueStatusShipment', status);
		this.router.navigateByUrl('/operation/shipment');
	}
}
