import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DataSharingService } from '../../../shared/services';

@Component({
  selector: 'app-images-viewer',
  templateUrl: 'images-viewer.component.html',
  styleUrls: ['images-viewer.component.scss'],
})
export class ImagesViewerComponent implements OnInit {
  showViewer: boolean = false;
  imagesList: any = [];
  subscriptionImagesViewer: Subscription;

  constructor(public dataSharingService: DataSharingService) {}

  ngOnInit() {
    this.subscriptionImagesViewer =
      this.dataSharingService.currentImagesViewer.subscribe((imagesViewer) => {
        this.showViewer = imagesViewer['showViewer'];
        this.imagesList = imagesViewer['imagesList'];
      });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this.closeImagesViewer();
    this.subscriptionImagesViewer.unsubscribe();
  }

  closeImagesViewer() {
    this.showViewer = false;
    this.dataSharingService.emitImagesViewerEvent({
      show: false,
      imagesList: [],
    });
  }
}
