import { Injectable } from '@angular/core';
import { HttpService } from '@core/services';
import { environment } from '@environment';

@Injectable({
    providedIn: 'root'
})
export class LocationsService {
    private prefixAuth: string;
    private prefixMasterData: string;

    constructor(private http: HttpService) {
        this.prefixAuth = environment.prefixApi.auth;
        this.prefixMasterData = environment.prefixApi.masterData;
    }

    getZoneList(param = '') {
        return this.http.Get(this.prefixMasterData + `/zones` + param);
    }

    getProvinceList() {
        return this.http.Get(this.prefixMasterData + `/provinces/?sort[name]=asc`);
    }

    getDistrictList(province_id) {
        return this.http.Get(this.prefixMasterData + `/districts/?sort[name]=asc&province_id=` + province_id);
    }

    getWardList(district_id) {
        return this.http.Get(this.prefixMasterData + `/wards/?sort[name]=asc&district_id=` + district_id);
    }

    getLocationList(param = '') {
        return this.http.Get(this.prefixMasterData + `/locations` + param);
    }

    getListCustomer(param = '') {
        return this.http.Get(this.prefixAuth + `/customer/list` + param);
    }

    getListActiveLocations(param = '') {
        return this.http.Get(this.prefixMasterData + `/locations/list-active` + param);
    }

    createLocation(data: any) {
        return this.http.Post(this.prefixMasterData + `/locations`, data);
    }

    getLocationDetails(id: number) {
        return this.http.Get(this.prefixMasterData + `/locations/` + id);
    }

    editLocation(id: number, data: Object) {
        return this.http.Put(this.prefixMasterData + `/locations/` + id, data);
    }

    deleteLocation(id: number) {
        return this.http.Delete(this.prefixMasterData + `/locations/` + id);
    }

    getCustomerList(roleCode = 'BCO') {
        return this.http.Get(this.prefixAuth + `/admin/users/by-role/` + roleCode);
    }

    getRampManagerList(roleCode = 'RM') {
        return this.http.Get(this.prefixAuth + `/admin/users/by-role/` + roleCode);
    }

    getLocationTypeList() {
        return this.http.Get(this.prefixMasterData + `/location-types/list-active`);
    }

    getTemRouteList() {
        return this.http.Get(this.prefixMasterData + `/route-template/list-active`);
    }

    getAutoCode(){
		return this.http.Get(this.prefixMasterData + `/locations/get-code`);
	}
}
