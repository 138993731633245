import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-confirm',
    templateUrl: './confirmation-dialog.component.html'
})

export class NgbdModalConfirm {
    title = 'Bạn có chắc chắn không ?';

    constructor(public modal: NgbActiveModal) { }
}
