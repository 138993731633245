import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtils } from './app.utils';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class AppHelpers {
  isUnauthorized = false;

  routeStatus = [
    { id: 1, name: 'New', code: 'OP' },
    { id: 2, name: 'In-progress', code: 'IP' },
    { id: 3, name: 'Finished', code: 'DV' },
    { id: 4, name: 'Cancelled', code: 'CC' },
  ];
  orderStatus = [
    { id: 1, name: 'New', code: 'OP' },
    { id: 2, name: 'Cancelled', code: 'CC' },
    { id: 3, name: 'Loaded', code: 'LD' },
    { id: 4, name: 'Warehouse Clarification', code: 'WC' },
    { id: 5, name: 'Partial Loaded', code: 'PL' },
    { id: 6, name: 'In Transit', code: 'IT' },
    { id: 7, name: 'Unable to Finish', code: 'UF' },
    { id: 8, name: 'Partial Delivered', code: 'PD' },
    { id: 9, name: 'Delivered', code: 'DV' },
  ];

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private http: HttpClient
  ) {}

  public showToast(type: string, message: any) {
    let formattedMessage = '';
    switch (type) {
      case 'success':
        if (message && typeof message === 'string') {
          formattedMessage = this.isUnauthorized
            ? this.translate.instant('Your session has expired.')
            : this.translate.instant(message);
          this.toastr.success(this.translate.instant(formattedMessage));
        }
        if (message && message instanceof Array && message.length > 0) {
          for (let i = 0; i < message.length; i++) {
            if (message[i] && typeof message[i] === 'string') {
              this.toastr.success(this.translate.instant(message[i]));
            }
          }
        }
        break;
      case 'error':
        if (message && typeof message === 'string') {
          formattedMessage = this.isUnauthorized
            ? this.translate.instant('Your session has expired.')
            : this.translate.instant(message);
          this.toastr.error(this.translate.instant(formattedMessage));
        }
        if (message && message instanceof Array && message.length > 0) {
          for (let i = 0; i < message.length; i++) {
            if (message[i] && typeof message[i] === 'string') {
              this.toastr.error(this.translate.instant(message[i]));
            }
          }
        }
        break;
      case 'warning':
        if (message && typeof message === 'string') {
          formattedMessage = this.isUnauthorized
            ? this.translate.instant('Your session has expired.')
            : this.translate.instant(message);
          this.toastr.warning(this.translate.instant(formattedMessage));
        }
        if (message && message instanceof Array && message.length > 0) {
          for (let i = 0; i < message.length; i++) {
            if (message[i] && typeof message[i] === 'string') {
              this.toastr.warning(this.translate.instant(message[i]));
            }
          }
        }
        break;
    }
  }

  public getUnauthorizationStatus() {
    return this.isUnauthorized;
  }

  public setUnauthorizationStatus(status: boolean) {
    this.isUnauthorized = status;
  }

  public getCountriesMasterdata() {
    return this.http.get('../../assets/i18n/countries.json');
  }

  public getCountryInfoByISOCode(countriesList, countryISOCode?: any) {
    if (countryISOCode) {
      return countriesList[countryISOCode];
    }
    return countriesList[environment.defaultCountryISOCode];
  }

  public getCountryISOCodeFromPhone(countriesList, phone) {
    let countryISOCode = environment.defaultCountryISOCode;
    for (const key in countriesList) {
      if (phone.indexOf(countriesList[key]['countryCode']) > -1) {
        countryISOCode = countriesList[key]['countryISOCode'];
      }
    }
    return countryISOCode;
  }

  public hasPermission(permissionName): boolean {
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    let result = false;

    if (permissions && permissions instanceof Array && permissions.length > 0) {
      for (let i = 0; i < permissions.length; i++) {
        if (
          permissions[i].children &&
          permissions[i].children instanceof Array &&
          permissions[i].children.length > 0
        ) {
          result = AppUtils.hasKeyValueInArray(
            permissions[i].children,
            'cd',
            permissionName
          );
          if (result) {
            break;
          } else {
            continue;
          }
        }
      }
    }

    return result;
  }

  isADateStringValid(stringDate) {
    // Wrong at this case but we can accept it: 'October 13, 2014 11:13:00'
    const strTemp =
      stringDate && typeof stringDate === 'string' ? stringDate.trim() : '';
    const parsedStr = Date.parse(strTemp);
    if (
      isNaN(parsedStr) ||
      parsedStr < 0 ||
      isNaN(parseFloat(strTemp.charAt(0)))
    ) {
      return false;
    } else {
      return true;
    }
  }

  isDateValid(stringDate) {
    // Fomart: 20/10/2021
    let regex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;
    return regex.test(stringDate);
  }

  public configDateCommon() {
    return {
      format: 'DD/MM/YYYY',
      locale: moment.locale(AppUtils.getCurrentLanguageCode()),
      useStrict: true,
      ignoreReadonly: true,
      icons: {
        date: 'far fa-calendar-alt',
      },
      tooltips: {
        today: this.translate.instant('DatePicker_Go_To_Today'),
        clear: this.translate.instant('DatePicker_Clear_Selection'),
        close: this.translate.instant('DatePicker_Close_The_Picker'),
        selectMonth: this.translate.instant('DatePicker_Select_Month'),
        prevMonth: this.translate.instant('DatePicker_Previous_Month'),
        nextMonth: this.translate.instant('DatePicker_Next_Month'),
        selectYear: this.translate.instant('DatePicker_Select_Year'),
        prevYear: this.translate.instant('DatePicker_Previous_Year'),
        nextYear: this.translate.instant('DatePicker_Next_Year'),
        selectDecade: this.translate.instant('DatePicker_Select_Decade'),
        prevDecade: this.translate.instant('DatePicker_Previous_Decade'),
        nextDecade: this.translate.instant('DatePicker_Next_Decade'),
        prevCentury: this.translate.instant('DatePicker_Previous_Century'),
        nextCentury: this.translate.instant('DatePicker_Next_Century'),
        pickHour: this.translate.instant('DatePicker_Pick_Hour'),
        incrementHour: this.translate.instant('DatePicker_Increment_Hour'),
        decrementHour: this.translate.instant('DatePicker_Decrement_Hour'),
        pickMinute: this.translate.instant('DatePicker_Pick_Minute'),
        incrementMinute: this.translate.instant('DatePicker_Increment_Minute'),
        decrementMinute: this.translate.instant('DatePicker_Decrement_Minute'),
        pickSecond: this.translate.instant('DatePicker_Pick_Second'),
        incrementSecond: this.translate.instant('DatePicker_Increment_Second'),
        decrementSecond: this.translate.instant('DatePicker_Decrement_Second'),
        togglePeriod: this.translate.instant('DatePicker_Toggle_Period'),
        selectTime: this.translate.instant('DatePicker_Select_Time'),
        selectDate: this.translate.instant('DatePicker_Select_Date'),
      },
    };
  }

  exportExcel(pathOfReport: string, fileNameOfReport: string) {
    const path = environment.baseUrl + pathOfReport;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwt'),
      }),
      responseType: 'blob' as 'json',
    };
    document.getElementById('loading').classList.add('active');
    this.http.get(path, httpOptions).subscribe(
      (response) => {
        try {
          // @ts-ignore
          if (response.type != 'application/json') {
            this.downLoadFile(response, fileNameOfReport);
            document.getElementById('loading').classList.remove('active');
          }
        } catch (error) {
          console.log(error);
        }
      },
      (error) => {
        // this.showToast('error', error.message);
      }
    );
  }

  exportPDF(pathOfReport: string, fileNameOfReport: string) {
    const path = environment.baseUrl + pathOfReport;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwt'),
      }),
      responseType: 'blob' as 'json',
    };
    document.getElementById('loading').classList.add('active');

    this.http.get(path, httpOptions).subscribe(
      (response) => {
        try {
          // @ts-ignore
          if (response.type != 'application/json') {
            this.downLoadFile1(response, fileNameOfReport);
            document.getElementById('loading').classList.remove('active');
          }
        } catch (error) {
          console.log(error);
        }
      },
      (error) => {
        // this.showToast('error', error.message);
      }
    );
  }

  private downLoadFile(data: any, fileNameOfReport: string) {
    if (window.navigator.msSaveBlob) {
      const fileName = fileNameOfReport + '.xlsx';
      window.navigator.msSaveBlob(data, fileName);
    } else {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(data);
      a.download = fileNameOfReport + '.xlsx';
      document.body.appendChild(a);
      a.click();
    }

    this.showToast('success', 'Xuất báo cáo thành công!');
  }

  private downLoadFile1(data: any, fileNameOfReport: string) {
    if (window.navigator.msSaveBlob) {
      const fileName = fileNameOfReport + '.pdf';
      window.navigator.msSaveBlob(data, fileName);
    } else {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(data);
      a.download = fileNameOfReport + '.pdf';
      document.body.appendChild(a);
      a.click();
    }

    this.showToast('success', 'Xuất báo cáo PDF thành công!');
  }

  checkBeforeRoute(): boolean {
    const beforeRoute: string = sessionStorage.getItem('before-route');
    const currentRoute: string = this.router.url;

    return beforeRoute === currentRoute;
  }

  getCurrentPage(): any {
    return JSON.parse(sessionStorage.getItem('current-page'));
  }

  removeSessionCurrentPage(): void {
    sessionStorage.removeItem('current-page');
    sessionStorage.removeItem('before-route');
  }
}
