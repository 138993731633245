import * as moment from "moment";
import * as momentTz from 'moment-timezone';
import { environment } from "../../../environments/environment";

export class AppUtils {

    static objectDateTimeToString(obj) {
        return obj.year + '-' + obj.month + '-' + obj.day;
    }

    static objectDateTimeToString1(obj) {
        return obj.month + '/' + obj.day + '/' + obj.year;
    }

    static getItemInArrayObject(array, key, value) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] == value) {
                return array[i];
            }
        }
        return false;
    }

    static getIdFromObject(array: any, key: string) {
        let result = [];
        for (let i = 0; i < array.length; i++) {
            result.push(array[i][key].toString());
        }
        return result;
    }

    static hasKeyValueInArray(array, key, value) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] == value) {
                return true;
            }
        }
        return false;
    }

    static getNameFromIdInArray(id, array, alternativeField?) {
        let tempStr = '';
        for (let i = 0; i < array.length; i++) {
            if (Array.isArray(id)) { // if input is an array of ids
                tempStr += id.includes(array[i].id.toString()) || id.includes(+array[i].id) ? (array[i][alternativeField] ? array[i][alternativeField] : (array[i].name ? array[i].name : '')) + ', ' : '';
            } else {
                tempStr += array[i].id == id ? (array[i][alternativeField] ? array[i][alternativeField] : (array[i].name ? array[i].name : '')) + ', ' : '';
            }
        }
        tempStr = tempStr.length > 0 && tempStr.includes(',') ? tempStr.substring(0, tempStr.length - 2) : '';
        return tempStr;
    }

    static compareWithCurrentTime(dateTime) {
        let dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
        let currentTzName = momentTz.tz.guess();

        let oneMinuteBefore = moment(new Date());
        oneMinuteBefore.minute(oneMinuteBefore.minute() - 1);
        let oneMinuteVal = oneMinuteBefore.valueOf();

        let inputDateTime = moment(dateTime).format(dateTimeFormat);
        let updatedAt = momentTz.tz(inputDateTime, dateTimeFormat, environment.defaultAppTimezone).tz(currentTzName);
        let updatedAtVal = updatedAt.valueOf();

        return updatedAtVal < oneMinuteVal;
    }

    static convertDateTimeFormat(inputDateTimeString, inputFormat, outputFormat) {
        if (inputDateTimeString) {
            return moment(inputDateTimeString, inputFormat).format(outputFormat);
        }
        return '';
    }

    static getCurrentLanguageCode() {
        const currentLanguageCode = localStorage.getItem('lang');
        return currentLanguageCode ? currentLanguageCode : 'en';
    }

    static getPermitRequireToDropdown() {
        return [
            { id: 1, name: 'Truck', value: 'App\\Models\\Truck' },
            { id: 2, name: 'Location', value: 'App\\Models\\Location' },
            { id: 3, name: 'SKU or ITEM', value: 'App\\Models\\Package' },
            { id: 4, name: 'Driver', value: 'App\\Models\\User' }
        ];
    }

    static roundToDecimal(number, decimal) {
        const tenPowByDecimal = Math.pow(10, decimal);
        return Math.round(number * tenPowByDecimal) / tenPowByDecimal;
    }

    static fillStringPad(number, maxLength, fillString) {
        return String(number).padStart(maxLength, fillString)
    }

    static getDefaultMapTypeStyleArray() {
        return <google.maps.MapTypeStyle[]>[
            {
                featureType: 'all',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        weight: 2.00
                    }
                ]
            },
            {
                featureType: 'all',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#9c9c9c'
                    }
                ]
            },
            {
                featureType: 'all',
                elementType: 'labels.text',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        color: '#f2f2f2'
                    }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'landscape.man_made',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100
                    },
                    {
                        lightness: 45
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#eeeeee'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#7b7b7b'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                    {
                        color: '#46bcec'
                    },
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#c8d7d4'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#070707'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            }
        ]
    }

    static getDefaultStyledMapType() {
        return new google.maps.StyledMapType(
            this.getDefaultMapTypeStyleArray()
        );
    }

    static getDefaultAppLocation() {
        return environment.defaultAppLocation;
    }

}
