import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { SearchService } from './core/services/search.service';

@Component({
  selector: 'app-root',
  template: ` <router-outlet>
    <div class="loading-overlay" id="loading" [class.active]="loading">
      <div class="app-loading">
        <img src="/assets/images/truck.gif" width="220" height="150" />
      </div>
    </div>
  </router-outlet>`,
})
export class AppComponent {
  loading: boolean;

  constructor(
    private router: Router,
    private searchService: SearchService,
    private translate: TranslateService
  ) {
    this.loading = true;

    this.translate.addLangs(['en', 'vi']);
    const defaultLangCode: string = localStorage.getItem('lang');

    if (!defaultLangCode) {
      localStorage.setItem('lang', 'vi');
    }

    this.translate.setDefaultLang(defaultLangCode || 'vi');
    this.translate.use(defaultLangCode || 'vi');
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart:
          const urlCurrent = sessionStorage.getItem('urlCurrent');
          if (this.searchService.getUrl(event) !== urlCurrent) {
            sessionStorage.removeItem('filter');
          }
          break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
          this.loading = false;
          break;
      }
    });
  }
}
