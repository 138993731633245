import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { TranslateLoader } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class TranslationService implements TranslateLoader {
  constructor(private handler: HttpBackend, private http: HttpClient) {
    this.http = new HttpClient(this.handler);
  }

  getTranslation(lang: string): Observable<any> {
    return this.http
      .get<any>(environment.path_lang + lang + ".json")
      .map((response: JSON) => {
        return response;
      })
      .catch((err) => {
        return this.http
          .get<any>("./assets/i18n/" + lang + ".json")
          .map((response: JSON) => {
            return response;
          });
      });
  }
}
