import { Directive, Optional, Self } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
  selector:
    '[input[formControlName=name]], [input[formControlName=first_name]], [input[formControlName=contact_name]], [input[formControlName=username]]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(blur)': 'onBlur($event)',
    '(paste)': 'onPaste($event)',
  },
  providers: [NgModel],
})
export class BlockEnterSpecialCharactersDirective {
  constructor(
    private ngModel: NgModel,
    @Optional()
    @Self()
    private formControl: NgControl
  ) {}

  onBlur($event) {}

  onPaste(event) {
    const data = event.clipboardData.getData('text/plain') || '';
    // tslint:disable-next-line:max-line-length
    const regEx = new RegExp(
      // tslint:disable-next-line: max-line-length
      `^[a-zA-Z0-9aáàạãảăắằặẵẳâấầậẫẩbcdđeéèẹẽẻêếềệễểghiíìịĩỉklmnoóòọõỏôốồộỗổơớờợỡởpqrstuúùụũủưứừựữữvxyýỳỵỹỷAÁÀẠÃẢĂẮẰẶẴẲÂẤẦẬẪẨBCDĐEÉÈẸẼẺÊẾỀỆỄỂGHIÍÌỊĨỈKLMNOÓÒỌÕỎÔỐỒỘỖỔƠỚỜỢỠỞPQRSTUÚÙỤŨỦƯỨỪỰỮỮVXYÝỲỴỸỶ.!@?#\"$%&:';()*\+,\/; \-=[\\\]\^_{|}<>~\`]*$`
    );
    if (!regEx.test(data)) {
      event.preventDefault();
      return false;
    }
  }
}
